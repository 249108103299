import React from "react";
import "../styles/FeedbackSlider.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "reactstrap";

class AboutSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        767: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    };
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="expert-feedback grapping">
        <Container>
          <div className="title-box">
            <h2>
              Expert <b>Feadback</b>
            </h2>
            <p>
              Extremely painful. Nor again is there anyone who loves or pursues
              or desires to obtain pain of itself, because it is pain
            </p>
          </div>
          <Slider {...settings}>
            <div className="item">
              <div className="feedback-box">
                <div className="client-info">
                  <div className="client-img">
                    <img
                      src={require("./../images/client-img1.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="mb-2">josh brolin</h5>
                  <p>Web Developer</p>
                </div>
                <p>
                  We denounce with righteous indignation and dislike men who are
                  so beguiled and demoralized by the charms of pleasure of the
                  moment. when our power of choice is untrammelled and when
                  nothing prevents.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="feedback-box">
                <div className="client-info">
                  <div className="client-img">
                    <img
                      src={require("./../images/client-img2.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="mb-2">josh brolin</h5>
                  <p>Web Developer</p>
                </div>
                <p>
                  We denounce with righteous indignation and dislike men who are
                  so beguiled and demoralized by the charms of pleasure of the
                  moment. when our power of choice is untrammelled and when
                  nothing prevents.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="feedback-box">
                <div className="client-info">
                  <div className="client-img">
                    <img
                      src={require("./../images/client-img3.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="mb-2">josh brolin</h5>
                  <p>Web Developer</p>
                </div>
                <p>
                  We denounce with righteous indignation and dislike men who are
                  so beguiled and demoralized by the charms of pleasure of the
                  moment. when our power of choice is untrammelled and when
                  nothing prevents.
                </p>
              </div>
            </div>
            <div className="item">
              <div className="feedback-box">
                <div className="client-info">
                  <div className="client-img">
                    <img
                      src={require("./../images/client-img1.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="mb-1">josh brolin</h5>
                  <p>Web Developer</p>
                </div>
                <p>
                  We denounce with righteous indignation and dislike men who are
                  so beguiled and demoralized by the charms of pleasure of the
                  moment. when our power of choice is untrammelled and when
                  nothing prevents.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="feedback-box">
                <div className="client-info">
                  <div className="client-img">
                    <img
                      src={require("./../images/client-img2.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="mb-1">josh brolin</h5>
                  <p>Web Developer</p>
                </div>
                <p>
                  We denounce with righteous indignation and dislike men who are
                  so beguiled and demoralized by the charms of pleasure of the
                  moment. when our power of choice is untrammelled and when
                  nothing prevents.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="feedback-box">
                <div className="client-info">
                  <div className="client-img">
                    <img
                      src={require("./../images/client-img3.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="mb-1">josh brolin</h5>
                  <p>Web Developer</p>
                </div>
                <p>
                  We denounce with righteous indignation and dislike men who are
                  so beguiled and demoralized by the charms of pleasure of the
                  moment. when our power of choice is untrammelled and when
                  nothing prevents.
                </p>
              </div>
            </div>
          </Slider>
        </Container>
      </div>
    );
  }
}

export default AboutSlider;
