import React from "react";
import { Row, Col, Progress, Container } from "reactstrap";
import "../styles/AboutSlider.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class AboutSlider extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="section about-box grapping">
        <Container>
          <div className="title-box">
            <h2>About</h2>
            <p>
              Work Skill Bridge is a premier consulting and staffing firm
              dedicated to bridging the gap between skilled professionals and
              thriving industries. With decades of experience, we specialize in
              providing comprehensive staffing solutions, talent acquisition,
              and career development services tailored to meet the unique needs
              of factories and industries.
            </p>
          </div>
          <Row>
            <Col xs="12" sm="12" md="12" lg="6">
              <div className="about-img">
                <img
                  src={require("../images/aboutus.jpg")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>
            <Col xs="12" sm="12" md="12" lg="6">
              <Slider {...settings}>
                <div className="item">
                  <div className="title-box mb-0 text-left">
                    <h2 className="mb-0">
                      About <b>Work Skill Bridge</b>
                    </h2>
                    <h4 className="mt-0 mb-2 sub-title">
                      We Are Supporting <span>Industrial Excellence</span>
                    </h4>
                    <p>
                      Supplying qualified workers to meet the dynamic needs of
                      factories and industries.
                    </p>
                  </div>
                  <Row className="mt-4">
                    <Col className="text-left">
                      <h6 className="mb-0">Industry Expertise</h6>
                    </Col>
                    <Col className="text-right">
                      <h6 className="mb-0">95%</h6>
                    </Col>
                  </Row>
                  <Progress
                    value="95"
                    animated
                    color="orange"
                    className="mb-3"
                  />
                  <Row>
                    <Col className="text-left">
                      <h6 className="mb-0">Comprehensive Services</h6>
                    </Col>
                    <Col className="text-right">
                      <h6 className="mb-0">95%</h6>
                    </Col>
                  </Row>
                  <Progress
                    value={95}
                    animated
                    color="orange"
                    className="mb-3"
                  />
                  <Row>
                    <Col className="text-left">
                      <h6 className="mb-0">Client-Centric</h6>
                    </Col>
                    <Col className="text-right">
                      <h6 className="mb-0">95%</h6>
                    </Col>
                  </Row>
                  <Progress
                    value={95}
                    animated
                    color="orange"
                    className="mb-3"
                  />
                  <Row>
                    <Col className="text-left">
                      <h6 className="mb-0">Proven Track Record</h6>
                    </Col>
                    <Col className="text-right">
                      <h6 className="mb-0">92%</h6>
                    </Col>
                  </Row>
                  <Progress value="92" animated color="orange" />
                </div>

                <div className="item">
                  <div className="title-box text-left mb-0">
                    <h2 className="mb-0">
                      Know About Our <b>Mission</b>
                    </h2>
                    <h4 className="mt-0 mb-2 sub-title">
                      We Are Supporting <span>Industrial Excellence</span>
                    </h4>
                  </div>
                  <p>
                    Enhance skills and employability through comprehensive
                    training programs, ensuring candidates are prepared for
                    modern industries.
                  </p>
                  <p>
                    Meet the staffing needs of factories and industries with
                    skilled workers, driving productivity and innovation.
                  </p>
                  <p>
                    Provide career advancement opportunities by matching the
                    right talent with the right jobs, guiding professionals
                    through their career journey.
                  </p>
                  <p>
                    Deliver high-value solutions, building trust and exceeding
                    client expectations for mutual growth and success.
                  </p>
                </div>

                <div className="item">
                  <div className="title-box text-left mb-0">
                    <h2 className="mb-0">
                      Why Choose <b>Us</b>
                    </h2>
                    <h4 className="mt-0 mb-2 sub-title">
                      We Are Supporting <span>Industrial Excellence</span>
                    </h4>
                    <p>
                      Proven success in matching the right talent with the right
                      jobs.
                    </p>
                  </div>
                  <div className="why-choose">
                    <Row noGutters>
                      <Col xs="6" sm="6" md="4">
                        <div className="why-box text-center">
                          <i className="icofont-drag2" />
                          <h5>Efficient Recruitment Process</h5>
                        </div>
                      </Col>
                      <Col xs="6" sm="6" md="4">
                        <div className="why-box text-center">
                          <i className="icofont-live-support" />
                          <h5>Customized Training Programs</h5>
                        </div>
                      </Col>
                      <Col xs="6" sm="6" md="4">
                        <div className="why-box text-center">
                          <i className="icofont-support" />
                          <h5>Expertise in Industrial Staffing</h5>
                        </div>
                      </Col>
                      <Col xs="6" sm="6" md="4">
                        <div className="why-box text-center">
                          <i className="icofont-unique-idea" />
                          <h5>Extensive Talent Pool</h5>
                        </div>
                      </Col>
                      <Col xs="6" sm="6" md="4">
                        <div className="why-box text-center">
                          <i className="icofont-team" />
                          <h5>High Client Satisfaction</h5>
                        </div>
                      </Col>
                      <Col xs="6" sm="6" md="4">
                        <div className="why-box text-center">
                          <i className="icofont-search-user" />
                          <h5>Innovative Solutions</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AboutSlider;
