import React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Container, Row, Col } from "reactstrap";
import "../styles/Portfolio.css";

const images = [
  {
    src: require("./../images/portfolio-img1.jpg"),
    title: "Manufacturing Excellence",
    description:
      "Successful staffing of skilled labor in leading manufacturing plants.",
  },
  {
    src: require("./../images/portfolio-img7.jpg"),
    title: "Construction Projects",
    description:
      "Supporting large-scale construction projects with skilled workers.",
  },
  {
    src: require("./../images/portfolio-img3.jpg"),
    title: "Automotive Industry Success",
    description: "Supplying top talent to major automotive manufacturers.",
  },
  {
    src: require("./../images/portfolio-img4.jpeg"),
    title: "Electronics Sector",
    description:
      "Providing highly trained technicians to electronics factories.",
  },
  {
    src: require("./../images/portfolio-img5.png"),
    title: "Textile Industry",
    description:
      "Efficient staffing solutions for textile mills and factories.",
  },
  {
    src: require("./../images/portfolio-img6.jpg"),
    title: "Energy Sector",
    description:
      "Provided specialized personnel to power plants and renewable energy projects.",
  },
];

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    const { photoIndex, isOpen } = this.state;

    let image_array = images.map((image, key) => {
      return (
        <Col sm="6" md="4" key={key}>
          <div className="portfolio-box">
            <div className="portfolio-img">
              <img
                src={image.src}
                alt=""
                className="img-fluid"
                onClick={() => this.setState({ isOpen: true, photoIndex: key })}
                width="350px"
                height="261px"
              />
            </div>
            <h5 className="mt-3"> {image.title} </h5>
          </div>
        </Col>
      );
    });
    return (
      <div className="section our-portfolio grapping" id="portfolio">
        <Container>
          <div className="title-box">
            <h2>
              Our <b>Portfolio</b>
            </h2>
            <p>
              To be a leading consulting firm in India, driving success through
              the exceptional value we deliver to our clients, the outstanding
              quality of our team, and the strength and spirit of our
              organization.
            </p>
          </div>
          <div>
            <Row>{image_array}</Row>

            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]["src"]}
                nextSrc={images[(photoIndex + 1) % images.length]["src"]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length][
                    "src"
                  ]
                }
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + images.length - 1) % images.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length,
                  })
                }
              />
            )}
          </div>
        </Container>
      </div>
    );
  }
}

export default Portfolio;
