import React from "react";
import "../styles/About.css";
import { Container, Row, Col } from "reactstrap";

class About extends React.Component {
  render() {
    return (
      <div className=" welcome-box grapping" id="about">
        <Container>
          <div className="title-box">
            <h2>
              Welcome to <b>Work Skill Bridge</b>
            </h2>
            <p>
              Work Skill Bridge is dedicated to connecting skilled labor with
              job opportunities that match their talents and interests.
            </p>
          </div>
          <Row>
            <Col xs="12" sm="12" md="4">
              <div className="white-bg wel-box common-box">
                <i className="icofont-code" />
                <h4>Efficient Recruitment Process</h4>
                <p>
                  Streamlined hiring to quickly meet staffing needs. Access to a
                  vast network of qualified professionals.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="4">
              <div className="white-bg wel-box common-box">
                <i className="icofont-drag2" />
                <h4>High Client Satisfaction</h4>
                <p>
                  Proven success in matching the right talent with the right
                  jobs.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="4">
              <div className="white-bg wel-box common-box">
                <i className="icofont-live-support" />
                <h4>Expertise in Industrial Staffing</h4>
                <p>
                  Specialized in supplying skilled workers to factories and
                  industries.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default About;
