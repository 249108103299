import React from "react";
import { Container, Button } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Banner.css";

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: {
        0: {
          nav: false,
          dots: true,
        },
        767: {
          nav: true,
        },
      },
    };
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <section className="banner">
        <Slider {...settings}>
          <div className="item">
            <div className="banner-one banner-img">
              <Container>
                <div className="banner-text text-center">
                  <h1>
                    Connecting <span>Talent</span> with Opportunity
                  </h1>
                  <p>
                    Bridging the gap between skilled professionals and thriving
                    industries.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Button href="#contact" color="primary mr-3">
                      Get Started
                    </Button>
                    {/* <VideoModal></VideoModal> */}
                  </div>
                </div>
              </Container>
            </div>
          </div>
          <div className="item">
            <div className="banner-two banner-img">
              <Container>
                <div className="banner-text text-center">
                  <h1>
                    Empowering <span>Workforce</span> Solutions
                  </h1>
                  <p>
                    Providing comprehensive staffing solutions to drive
                    industrial growth.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Button href="#contact" color="primary mr-3">
                      Get Started
                    </Button>
                    {/* <VideoModal></VideoModal> */}
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </Slider>
      </section>
    );
  }
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-next`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-prev`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

export default Banner;
