import React from "react";
import { Container, Row, Col, Media } from "reactstrap";
import "../styles/Marketing.css";

class Marketing extends React.Component {
  render() {
    return (
      <div className="marketing-section grapping">
        <Container>
          <Row className="align-items-center">
            <Col xs="12" sm="12" md="12" lg="6">
              <div className="marketing-img">
                <img
                  src={require("../images/digitalmarketing.jpg")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>
            <Col xs="12" sm="12" md="12" lg="6">
              <Media className="market-box">
                <Media left href="#">
                  <i className="icofont-web" />
                </Media>
                <Media body>
                  <Media heading>Needs Assessment</Media>
                  Understanding your specific staffing requirements and
                  challenges.
                </Media>
              </Media>
              <Media className="market-box">
                <Media left href="#">
                  <i className="icofont-chart-pie" />
                </Media>
                <Media body>
                  <Media heading>Talent Matching</Media>
                  Identifying and recruiting the best candidates to meet your
                  needs.
                </Media>
              </Media>
              <Media className="market-box">
                <Media left href="#">
                  <i className="icofont-brand-designfloat" />
                </Media>
                <Media body>
                  <Media heading>Onboarding and Support</Media>
                  Facilitating seamless integration of new hires and providing
                  ongoing support.
                </Media>
              </Media>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Marketing;
