import React from "react";
import "../styles/Services.css";
import { Container, Row, Col } from "reactstrap";

class Services extends React.Component {
  render() {
    return (
      <div className="section our-service grapping" id="services">
        <Container>
          <div className="title-box">
            <h2>
              Our <b>Services</b>
            </h2>
            <p>
              Our services are designed to empower organizations with the right
              talent, strategic guidance, and innovative solutions that drive
              growth, efficiency, and success.
            </p>
          </div>
          <Row>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box">
                <i className="icofont-crown" />
                <h4>Workforce Management</h4>
                <p>
                  Managing all aspects of workforce logistics and deployment.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box">
                <i className="icofont-pen-alt-3" />
                <h4>Performance</h4>
                <p>
                  Implementing strategies to improve worker productivity and
                  efficiency.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box">
                <i className="icofont-camera-alt" />
                <h4>Talent Acquisition</h4>
                <p>
                  Identifying and recruiting top talent for your business needs.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box">
                <i className="icofont-chart-growth" />
                <h4>Training & Development</h4>
                <p>
                  Offering comprehensive training programs to enhance worker
                  skills.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box">
                <i className="icofont-laptop-alt" />
                <h4>HR Consulting</h4>
                <p>
                  Providing advice on human resource management and compliance.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box">
                <i className="icofont-tick-boxed" />
                <h4>Career Counseling</h4>
                <p>
                  Assisting job seekers with career planning and development.
                </p>
              </div>
            </Col>
            <Col xs="15" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box">
                <i className="icofont-paper" />
                <h4>Market Analysis</h4>
                <p>
                  Conducting market research to inform recruitment strategies.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box">
                <i className="icofont-search-job" />
                <h4>Industrial Staffing</h4>
                <p>
                  Providing skilled and semi-skilled workers for various
                  industrial roles.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Services;
