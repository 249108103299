import React from "react";
import "../styles/Team.css";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";

class Team extends React.Component {
  render() {
    return (
      <div className="section expert-team grapping" id="team">
        <Container>
          <div className="title-box">
            <h2>Expert <b>Team</b></h2>
            <p>When an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
          </div>
          <Row>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="text-center common-box team-box">
                <div className="team-img">
                  <img src={require("../images/team-img1.jpg")} alt="" className="img-fluid rounded-circle" />
                </div>
                <h4 className="text-white">Lucy Smith</h4>
                <p className="text-white">When an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                <ListGroup className="social-icon-team">
                  <ListGroupItem tag="a" href="#"><i className="icofont-facebook" /></ListGroupItem>
                  <ListGroupItem tag="a" href="#"><i className="icofont-twitter" /></ListGroupItem>
                  <ListGroupItem tag="a" href="#"><i className="icofont-instagram" /></ListGroupItem>
                  <ListGroupItem tag="a" href="#"><i className="icofont-linkedin" /></ListGroupItem>
                </ListGroup>
              </div>
            </Col>

            <Col xs="12" sm="6" md="6" lg="3">
              <div className="text-center common-box team-box">
                <div className="team-img">
                  <img src={require("../images/team-img2.jpg")} alt="" className="img-fluid rounded-circle" />
                </div>
                <h4 className="text-white">Alex Maxwel</h4>
                <p className="text-white">When an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                <ListGroup className="social-icon-team">
                  <ListGroupItem tag="a" href="#"><i className="icofont-facebook" /></ListGroupItem>
                  <ListGroupItem tag="a" href="#"><i className="icofont-twitter" /></ListGroupItem>
                  <ListGroupItem tag="a" href="#"><i className="icofont-instagram" /></ListGroupItem>
                  <ListGroupItem tag="a" href="#"><i className="icofont-linkedin" /></ListGroupItem>
                </ListGroup>
              </div>
            </Col>

            <Col xs="12" sm="6" md="6" lg="3">
              <div className="text-center common-box team-box">
                <div className="team-img">
                  <img src={require("../images/team-img3.jpg")} alt="" className="img-fluid rounded-circle" />
                </div>
                <h4 className="text-white">Steven Doe</h4>
                <p className="text-white">When an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                <ListGroup className="social-icon-team">
                  <ListGroupItem tag="a" href="#"><i className="icofont-facebook" /></ListGroupItem>
                  <ListGroupItem tag="a" href="#"><i className="icofont-twitter" /></ListGroupItem>
                  <ListGroupItem tag="a" href="#"><i className="icofont-instagram" /></ListGroupItem>
                  <ListGroupItem tag="a" href="#"><i className="icofont-linkedin" /></ListGroupItem>
                </ListGroup>
              </div>
            </Col>

            <Col xs="12" sm="6" md="6" lg="3">
              <div className="text-center common-box team-box">
                <div className="team-img">
                  <img src={require("../images/team-img4.jpg")} alt="" className="img-fluid rounded-circle" />
                </div>
                <h4 className="text-white"> Maxwel Doe </h4>
                <p className="text-white">When an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                <ListGroup className="social-icon-team">
                  <ListGroupItem tag="a" href="#"><i className="icofont-facebook" /></ListGroupItem>
                  <ListGroupItem tag="a" href="#"><i className="icofont-twitter" /></ListGroupItem>
                  <ListGroupItem tag="a" href="#"><i className="icofont-instagram" /></ListGroupItem>
                  <ListGroupItem tag="a" href="#"><i className="icofont-linkedin" /></ListGroupItem>
                </ListGroup>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Team;
