import React from "react";
import { Col, Container, Row } from "reactstrap";
import "../styles/OurBranch.css";

class OurBranch extends React.Component {
  render() {
    return (
      <div className="our-branch grapping">
        <Container>
          <Row className="our-branch-info">
            <Col md="3">
              <div>
                <img src="../logo/logo-b-l.svg" alt="logo" />
              </div>
            </Col>
            <Col md="3">
              <h4>Our Branches</h4>
              <ul>
                <li>Pune</li>
                <li>Ahmednagar</li>
                <li>Gujarat</li>
                <li>New Delhi</li>
                <li>Rajasthan</li>
              </ul>
            </Col>
            <Col md="3">
              <h4>Contact Us</h4>
              <p>
                <strong>Address:</strong> MIDC SUPA TAH. PARNER
                DIST AHMEDNAGAR MH-414301.
              </p>
              <p>
                <strong>Mobile:</strong> +918856031988
              </p>
              <p>
                <strong>Email:</strong> <a href="mailto:info@workskillbridge.com">info@workskillbridge.com</a>
              </p>
            </Col>
            <Col md="3">
              <h4>Location</h4>
              <iframe
                title="company-location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4476.123585049728!2d74.5195559!3d18.953298999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdccb745361b27f%3A0xee2ec707bffba62a!2sSupa%20MIDC!5e1!3m2!1sen!2sin!4v1724599381136!5m2!1sen!2sin"
                width="100%"
                height="200"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default OurBranch;
